import { MenuItem } from '@material-ui/core';
import { translate } from 'react-jhipster';
import Select from '@material-ui/core/Select';
import { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import { AvForm } from 'availity-reactstrap-validation';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import { IRootState } from '../../../shared/reducers';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { RouteComponentProps } from 'react-router';
import moment from 'moment-timezone';
import { DATE_TIME_FORMATS } from '../../../config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import '../style.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import {
  saveReminder,
  fetchConsultationDetails,
  getReminders,
  deleteReminders,
  getReminderById,
  getReminderTypes,
  updateReminder,
} from '../../../reducers/video-consultation-reducer';

interface ReminderType {
  id: number;
  name: string;
}
export interface IPageProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{ id: string }> {}

const Reminder = props => {
  const { appointmentId } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [showReminders, setShowReminders] = useState(false);
  const [types, setTypes] = useState<ReminderType[]>([]);
  const [remark, setRemark] = useState('');
  const [type, setType] = useState('');
  const [date, setDate] = useState(moment(new Date()).toDate());
  const [typeError, setTypeError] = useState(false);
  const [textError, setTextError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [reminderId, setReminderId] = useState(null);
  const [addOnReminder, setAddOnReminder] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isPayloadSaved = useSelector(
    (state: IRootState) => state.videoConsultation.payloadSaved,
  );

  const toggleDropdown = () => {
    setAddOnReminder(false);
    setShowDropdown(true);
    setShowReminders(false);
  };
  
  const onEditReminder = id => {
    setReminderId(id);
    setAddOnReminder(false);
    setShowDropdown(true);
    setShowReminders(false);
    props?.getReminderById(id);
  };

  const onDeleteReminder = async id => {
    try {
      setIsDeleting(true);
      await props.deleteReminders(id);
      await props.getReminders(appointmentId);

      setShowReminders(true);
    } catch (error) {
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    if (reminderId) {
      const selectedReminder = props?.getReminderByIdResponse;
      if (selectedReminder) {
        setType(selectedReminder?.reminderType?.id);
        setDate(moment(selectedReminder?.date).toDate());
        setRemark(selectedReminder?.remarks);
      }
    }
  }, [props?.getReminderByIdResponse]);

  useEffect(() => {
    if (appointmentId) {
      setIsLoading(true);
      props
        .getReminders(appointmentId)
        .then(() => {
          setIsLoading(false);
          setIsFormSubmitted(false);
        })
        .catch(error => {
          setIsLoading(false);
          setAddOnReminder(false);
        });
    }
  }, [appointmentId, isFormSubmitted, isPayloadSaved]);

  useEffect(() => {
    if (reminderId) {
      props?.getReminderById(reminderId);
    }
  }, [reminderId]);

  useEffect(() => {
    if (
      props?.getRemindersResponse?.length &&
      props?.getRemindersResponse?.length !== 0
    ) {
      setShowDropdown(false);
      setShowReminders(true);
      setAddOnReminder(true);
    } else {
      setShowDropdown(true);
      setAddOnReminder(false);
    }
  }, [props?.getRemindersResponse]);

  useEffect(() => {
    props.getReminderTypes();
  }, []);

  useEffect(() => {
    if (
      props?.getReminderTypesResponse?.length &&
      props?.getReminderTypesResponse?.length != 0
    ) {
      setTypes(props?.getReminderTypesResponse);
    }
  }, [props?.getReminderTypesResponse]);

  const onTypeChange = event => {
    const selectedType = event.target.value;
    setType(selectedType);
    if (event?.target?.value) {
      setTypeError(false);
    } else {
      setTypeError(true);
    }
  };

  const typeErrorChecking = () => {
    if (!type) {
      setTypeError(true);
      return true;
    } else {
      setTypeError(false);
      return false;
    }
  };
  const onDobChange = date => {
    setDate(moment(date).toDate());
    if (date) {
      setDateError(false);
    } else {
      setDateError(true);
    }
  };
  const onSetRemark = event => {
    setRemark(event.target.value);
  };
  const dateErrorChecking = () => {
    if (!type) {
      setDateError(true);
      return true;
    } else {
      setDateError(false);
      return false;
    }
  };
  const textErrorChecking = () => {
    if (!remark && type == '-2') {
      setTextError(true);
      return true;
    } else {
      setTextError(false);
      return false;
    }
  };

  const handleValidSubmit = async (event, errors, values) => {
    const typeErrorValid = typeErrorChecking();
    const dateErrorValid = dateErrorChecking();
    const textErrorValid = textErrorChecking();

    if (!typeErrorValid && !dateErrorValid && !textErrorValid) {
      const entity = {
        appointmentId,
        remarks: remark,
        typeId: type,
        date: moment(date).format(DATE_TIME_FORMATS.YYYY_MM_DD_WITH_HYPHEN),
      };

      if (reminderId) {
        props.updateReminder(entity, reminderId);
      } else {
        props.saveReminder(entity);
      }
      setIsFormSubmitted(true);
      setShowDropdown(false);
      setRemark('');
      setType('');
      setDate(moment(new Date()).toDate());
      setReminderId(null);
    }
  };

  const handleClose = () => {
    if (
      props?.getRemindersResponse?.length &&
      props?.getRemindersResponse?.length > 0
    ) {
      setShowDropdown(false);
      setIsFormSubmitted(true);
    }
    setRemark('');
    setType('');
    setDate(moment(new Date()).toDate());
  };

  return (
    <div className="reminders">
      {showDropdown && (
        <div className="reminder">
          <div className="reminder-block">
            <AvForm onValidSubmit={handleValidSubmit}>
              <div className="close-icon">
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={handleClose}
                  className="icon-large"
                />
              </div>
              <Select
                className="style-text-box style-text-box-pet select-value-new"
                variant="outlined"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                value={type || ''}
                displayEmpty
                error={typeError ? true : false}
                onChange={event => {
                  onTypeChange(event);
                }}>
                <MenuItem value="" disabled>
                  Select reminder type
                </MenuItem>
                {types?.map(attr => (
                  <MenuItem key={attr.id} value={attr.id}>
                    {attr.name}
                  </MenuItem>
                ))}
              </Select>

              {typeError && (
                <span className="text-danger" style={{ fontSize: '.8rem' }}>
                  Please select type
                </span>
              )}
              <div className="mt-3">
                <DatePicker
                  value={date ? moment(date) : undefined}
                  onChange={date => onDobChange(date)}
                  placeholder="dd-mm-yyyy"
                  format={DATE_TIME_FORMATS.DD_MM_YYYY}
                  allowClear={false}
                  className="select-value-new"
                />{' '}
              </div>
              <div className="mt-3">
                <input
                  type="text"
                  style={{
                    borderColor: textError ? 'red' : undefined,
                  }}
                  placeholder="Enter remarks"
                  className="form-control select-value-new"
                  value={remark}
                  onChange={onSetRemark}
                />
                {textError && (
                  <span className="text-danger" style={{ fontSize: '.8rem' }}>
                    Please enter remarks
                  </span>
                )}
              </div>
              <div className="save-button-center">
                <Button className="submit-btn add-reminder-button mt-3 text-right save-button">
                  <span>Save</span>
                </Button>
              </div>
            </AvForm>
          </div>
        </div>
      )}
      {isLoading ? (
        <div
          className="text-info my-4 d-flex justify-content-center"
          style={{ fontSize: 32 }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        showReminders &&
        props?.getRemindersResponse?.length > 0 &&
        props?.getRemindersResponse?.map(reminder => {
          let appointmentText;
          if (reminder?.reminderType?.name === 'VACCINATION') {
            appointmentText = `${translate(
              'consultation_tab.reminder.next_vaccine',
            )} ${moment(reminder?.date).format('DD-MM-YYYY')}`;
          } else if (reminder?.reminderType?.name === 'REVIEW SCHEDULE') {
            appointmentText = `${translate(
              'consultation_tab.reminder.next_appoinment',
            )} ${moment(reminder?.date).format('DD-MM-YYYY')}`;
          }
          return (
            <div className="d-flex flex-column" key={reminder?.id}>
              <div className="reminder-list">
                <div className="edit-show">
                  <div className="reminder-show">
                    {appointmentText && <span>{appointmentText}</span>}
                    {!appointmentText && (
                      <span>
                        {reminder?.remarks} -{' '}
                        {moment(reminder?.date).format('DD-MM-YYYY')}
                      </span>
                    )}
                  </div>
                  <div>
                    <button
                      className="edit-button"
                      onClick={() => onEditReminder(reminder?.id)}>
                      <FaPencilAlt className="edit-pencil pointer-style" />
                    </button>
                    <button
                      className="trash-button"
                      onClick={() => onDeleteReminder(reminder?.id)}>
                      <FaTrashAlt className="trash-box pointer-style" />
                    </button>
                  </div>
                </div>

                {appointmentText && (
                  <div className="remarks">
                    <span>{reminder?.remarks}</span>
                  </div>
                )}
              </div>
            </div>
          );
        })
      )}
      {isDeleting ? (
        <div
          className="text-info my-4 d-flex justify-content-center"
          style={{ fontSize: 32 }}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      ) : (
        addOnReminder && (
          <div className="add-reminder">
            <Button
              className="submit-btn add-reminder-button"
              onClick={toggleDropdown}>
              Add
            </Button>
          </div>
        )
      )}
    </div>
  );
};
const mapDispatchToProps = {
  saveReminder,
  fetchConsultationDetails,
  getReminders,
  deleteReminders,
  getReminderById,
  getReminderTypes,
  updateReminder,
};

const mapStateToProps = ({ videoConsultation, user }: IRootState) => ({
  saveReminderResponse: videoConsultation.saveReminderResponse,
  getRemindersResponse: videoConsultation.getRemindersResponse,
  updateReminderResponse: videoConsultation.updateReminderResponse,
  getReminderByIdResponse: videoConsultation.getReminderByIdResponse,
  loading: user.loading,
  getReminderTypesResponse: videoConsultation.getReminderTypesResponse,
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Reminder);
